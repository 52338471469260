import { Controller } from "stimulus"
import Rails from "@rails/ujs";

export default class extends Controller {

  static targets = [
    "delete_link"
  ]

  connect(){

  }


  remove_form(event){
    event.stopPropagation()
    event.preventDefault()
    let controller = this
    const id = event.currentTarget.getAttribute("data-id")

    Weglot.initialize({
      api_key: 'wg_5d495abf31c21cb3e5f9471b1149109a1',
      cache: true
    });

    swal({
      title: 'Are you sure?',
      text: "Removing cannot be undone",
      type: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, remove this embeddable form!',
      cancelButtonText: 'Cancel',
      confirmButtonClass: 'btn btn-lg btn-danger waves-effect waves-light m-t-20',
      cancelButtonClass: 'btn btn-lg btn-transparent waves-effect waves-light m-l-10 m-t-20',
      buttonsStyling: false
    }).then(function () {
        controller.delete_linkTarget.click()
    }, function (dismiss) {
        if (dismiss === 'cancel') {
          // window.location.href = link;
        }
    })
  }


}
