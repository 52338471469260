import { Controller } from "stimulus"
//import Rails from "@rails/ujs";
import consumer from "channels/consumer"

export default class extends Controller {
  static targets = [
    "imported_records",
    "duplicate_records",
    "errors",
    "table"
  ]

  connect(){
    console.log("summary_controller connected...");

    this.subscription = consumer.subscriptions.create({ channel: "ImportChannel", id: this.data.get("id")}, {
      connected: this._connected.bind(this),
      disconnected: this._disconnected.bind(this),
      received: this._received.bind(this)
    })
  }

  disconnect(){
    consumer.subscriptions.remove(this.subscription)
  }

  _connected(){

  }

  _disconnected(){

  }

  _received(data){
    console.log(data);
    this.imported_recordsTarget.innerText = data.imported_records
    this.duplicate_recordsTarget.innerText = data.found_duplicates
    this.errorsTarget.innerText = data.found_errors
    //console.log(data.error_feedback_data.length)
    if (data.error_feedback_data.length < 51) {
      this.render_table(data, data.error_feedback_data.length)
    } else {
      this.render_table(data, 51)
    }
  }

  render_table(data, size){
    this.tableTarget.innerHTML = ""
    for(let i = 0; i < size; i++ ) {
      let row = document.createElement("tr")
      let fullname = document.createElement("td")
      let fulllname_text = document.createTextNode(data.error_feedback_data[i]["full_name"])
      fullname.appendChild(fulllname_text)
      row.appendChild(fullname)

      let email = document.createElement("td")
      let email_text = document.createTextNode(data.error_feedback_data[i]["email"])
      email.appendChild(email_text)
      row.appendChild(email)

      let errors = document.createElement("td")
      let unordered_list = document.createElement("ul")

      if ( "email" in data.error_feedback_data[i]["errors"] ) {
        let list_item = document.createElement("li")
        let list_item_text = document.createTextNode(data.error_feedback_data[i]["errors"]["email"])
        list_item.appendChild(list_item_text)
        unordered_list.appendChild(list_item)
      }
      if ( "full_name" in data.error_feedback_data[i]["errors"] ) {
        let list_item = document.createElement("li")
        let list_item_text = document.createTextNode(data.error_feedback_data[i]["errors"]["full_name"])
        list_item.appendChild(list_item_text)
        unordered_list.appendChild(list_item)
      }
      if ( "first_name" in data.error_feedback_data[i]["errors"] ) {
        let list_item = document.createElement("li")
        let list_item_text = document.createTextNode(data.error_feedback_data[i]["errors"]["first_name"])
        list_item.appendChild(list_item_text)
        unordered_list.appendChild(list_item)
      }
      if ( "last_name" in data.error_feedback_data[i]["errors"] ) {
        let list_item = document.createElement("li")
        let list_item_text = document.createTextNode(data.error_feedback_data[i]["errors"]["last_name"])
        list_item.appendChild(list_item_text)
        unordered_list.appendChild(list_item)
      }
      if ( "gender" in data.error_feedback_data[i]["errors"] ) {
        let list_item = document.createElement("li")
        let list_item_text = document.createTextNode(data.error_feedback_data[i]["errors"]["gender"])
        list_item.appendChild(list_item_text)
        unordered_list.appendChild(list_item)
      }
      if ( "phone" in data.error_feedback_data[i]["errors"] ) {
        let list_item = document.createElement("li")
        let list_item_text = document.createTextNode(data.error_feedback_data[i]["errors"]["phone"])
        list_item.appendChild(list_item_text)
        unordered_list.appendChild(list_item)
      }
      if ( "country" in data.error_feedback_data[i]["errors"] ) {
        let list_item = document.createElement("li")
        let list_item_text = document.createTextNode(data.error_feedback_data[i]["errors"]["country"])
        list_item.appendChild(list_item_text)
        unordered_list.appendChild(list_item)
      }
      if ( "dob" in data.error_feedback_data[i]["errors"] ) {
        let list_item = document.createElement("li")
        let list_item_text = document.createTextNode(data.error_feedback_data[i]["errors"]["dob"])
        list_item.appendChild(list_item_text)
        unordered_list.appendChild(list_item)
      }
      if ( "first_visit_date" in data.error_feedback_data[i]["errors"] ) {
        let list_item = document.createElement("li")
        let list_item_text = document.createTextNode(data.error_feedback_data[i]["errors"]["first_visit_date"])
        list_item.appendChild(list_item_text)
        unordered_list.appendChild(list_item)
      }
      if ( "last_visit_date" in data.error_feedback_data[i]["errors"] ) {
        let list_item = document.createElement("li")
        let list_item_text = document.createTextNode(data.error_feedback_data[i]["errors"]["last_visit_date"])
        list_item.appendChild(list_item_text)
        unordered_list.appendChild(list_item)
      }

      errors.appendChild(unordered_list)
      row.appendChild(errors)
      this.tableTarget.appendChild(row)
    }
  }

}
