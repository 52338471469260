import { Controller } from "stimulus"
import Rails from "@rails/ujs";

export default class extends Controller {

  static targets = [
    "form"
  ]

  initialize() {

    document.querySelectorAll(".user_data_entries").forEach(function(elem){
      if (["name", "full name"].includes(elem.name.toLowerCase())) {
        elem.value = "Full Name";
      } else if (["first name"].includes(elem.name.toLowerCase())){
        elem.value = "First Name";
      } else if (["last name"].includes(elem.name.toLowerCase())){
        elem.value = "Last Name";
      } else if (["email", "email address"].includes(elem.name.toLowerCase())) {
        elem.value = "Email";
      } else if (["gender"].includes(elem.name.toLowerCase())) {
        elem.value = "Gender";
      } else if (["phone", "tel", "telephone", "sms", "mobile", "phone number"].includes(elem.name.toLowerCase())) {
        elem.value = "Phone";
      } else if (["birthday", "dob", "birth", "date of birth"].includes(elem.name.toLowerCase())) {
        elem.value = "Birthday";
      } else if (["country"].includes(elem.name.toLowerCase())) {
        elem.value = "Country";
      } else if (["subscribed"].includes(elem.name.toLowerCase())) {
        elem.value = "Subscribed"
      } else if (["unsubscribed"].includes(elem.name.toLowerCase())) {
        elem.value = "Unsubscribed"
      } else if (["first visit", "first visit date"].includes(elem.name.toLowerCase())){
        elem.value = "First Visit Date"
      } else if (["last visit", "last visit date"].includes(elem.name.toLowerCase())){
        elem.value = "Last Visit Date"
      }
    })

  }

  submit_form(event){
    event.preventDefault()
    let controller = this
    controller.errors = ""
    controller.email_or_phone_included = false
    controller.first_or_full_name_included = false
    controller.no_duplicate_headers = false
    controller.headers_array = new Array()
    controller.subscribed_fields = 0

    document.querySelectorAll(".user_data_entries").forEach(function(elem){

      if (elem.value == "Email" || elem.value == "Phone") {
        controller.email_or_phone_included = true
      }
      if (elem.value == "Full Name" || elem.value == "First Name"){
        controller.first_or_full_name_included = true
      }
      if(elem.value != "") {
        controller.headers_array.push(elem.value)
      }
      if (elem.value == "Subscribed" || elem.value == "Unsubscribed") {
        controller.subscribed_fields += 1
      }
    })

    if (controller.email_or_phone_included == false) {
      controller.errors += "<li>You have not selected an Email or Phone column.</li>"
    }

    if (controller.subscribed_fields > 1) {
      controller.errors += "<li>You have assigned more than one subscription column please only assign one.</li>"
    }

    for(let i = 0; i < controller.headers_array.length; i++) {
      if(controller.headers_array.indexOf(controller.headers_array[i]) === i) {
        controller.no_duplicate_headers = true
      } else {
        controller.no_duplicate_headers = false
        controller.errors += "<li>You have used " + controller.headers_array[i] + " more than once.</li>"
      }
    }
    if (controller.email_or_phone_included == true && controller.no_duplicate_headers == true && controller.first_or_full_name_included == true && controller.subscribed_fields < 2) { 
      this.showPopUp()
    } else if (controller.email_or_phone_included == true && controller.no_duplicate_headers == true && controller.subscribed_fields < 2){
      swal({
        title: 'Are you sure?',
        text: "You have not included a name column, this may be an issue when sending personalized emails to your guests",
        type: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Continue Anyway',
        cancelButtonText: 'Go Back and add Names',
        confirmButtonClass: 'btn btn-lg btn-danger waves-effect waves-light m-t-20',
        cancelButtonClass: 'btn btn-lg btn-transparent waves-effect waves-light m-l-10 m-t-20',
        buttonsStyling: false
      }).then(() => {
        this.showPopUp(); // Use an arrow function to maintain the correct 'this' context
      }, (dismiss) => {
        // Handle cancel
      });
    } else {
      jQuery("#errors_block").html("<div class='text-danger'><p>Review the following issues:</p><ul>" + this.errors + "</ul></div>")
    }
  }

  proceedForm(event) {
    event.preventDefault();

    $('#approval_form').submit();
  }

  showPopUp() {
    if ($('#data-source').data('showPopup') === false) {
      $('#approval_form').submit();
    } else {
      $('#change-guest-popup').modal('show');
    }
  }

}
