import { Controller } from "stimulus"

export default class extends Controller {
  addLocation(event) {
    event.stopPropagation();
    event.preventDefault();

    swal({
      title: 'Are you sure?',
      text: 'By adding this new location a Plug & Play Beambox router will be sent to you by mail',
      type: 'warning',
      showCancelButton: true,
      confirmButtonClass: 'btn btn-lg btn-primary waves-effect waves-light m-t-20',
      cancelButtonClass: 'btn btn-lg btn-transparent waves-effect waves-light m-l-10 m-t-20',
      confirmButtonText: 'Yes, add location!',
      buttonsStyling: false
    }).then((result) => {
      if (result) {
        jQuery('#add-delivery-address-modal').modal('toggle');
      }
    })
    .catch(() => {
      jQuery('#new-location-modal').modal('toggle');
    });
  }
}
