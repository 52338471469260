import { Controller } from "stimulus"

export default class extends Controller {

  static targets = [
    "button_text",
    "button_url",
    "link_button",
    "container",
    "type_icon",
    "link_icon"
  ]

  connect(){
    this.index_buttons()
  }

  show_url(){
    this.button_textTarget.classList.add("closed_button_field")
    this.button_urlTarget.classList.remove("closed_button_field")
    this.button_urlTarget.classList.add("open_button_field")
    this.link_buttonTarget.classList.remove("link_button_right")
    this.link_buttonTarget.classList.add("link_button_left")
    this.type_iconTarget.classList.remove("closed_icon")
    this.link_iconTarget.classList.add("closed_icon")
    this.containerTarget.setAttribute("style", "width:100%")
    this.button_urlTarget.focus()
  }

  hide_url(){
    this.button_textTarget.classList.remove("closed_button_field")
    this.button_urlTarget.classList.add("closed_button_field")
    this.button_urlTarget.classList.remove("open_button_field")
    this.link_buttonTarget.classList.add("link_button_right")
    this.link_buttonTarget.classList.remove("link_button_left")
    this.type_iconTarget.classList.add("closed_icon")
    this.link_iconTarget.classList.remove("closed_icon")
    this.containerTarget.removeAttribute("style")
  }

  compileJson(){
    let buttons = document.querySelectorAll(".button")
    let button_array = []
    buttons.forEach(function(button, index){
      let button_url = button.querySelectorAll('input')[1].value
      let button_text = button.querySelectorAll('input')[0].value
      if (button_url != ""){
        button_array.push({index: index, button_url: button_url, button_text: button_text})
      }
    })
    document.getElementById("welcome_one_buttons").value = JSON.stringify(button_array)
    this.update_iframe(button_array)
  }

  update_iframe(button_array){
    let html = ""
    button_array.forEach(function(button){
      if (button["button_text"].length > 0){
        html += `<div class="website-redirect"><a href="" class="website-redirect-btn" data-show-count="false" data-size="large" data-dnt="true">${button["button_text"]}</a></div>`
      }
    })
    const content = document.getElementById("mainframe-default").contentWindow.document
    content.querySelector("#buttons").innerHTML = html
  }

  remove_button(event){
    let index = event.currentTarget.getAttribute("data-index")
    let controller = this
    swal({
        title: 'Are you sure?',
        text: "Removing button cannot be undone",
        type: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes, remove button',
        cancelButtonText: 'No, go back',
        confirmButtonClass: 'btn btn-lg btn-danger waves-effect waves-light',
        cancelButtonClass: 'btn btn-lg btn-transparent waves-effect waves-light m-l-10',
        buttonsStyling: false
    }).then(function () {
        document.querySelector(`.button[data-index="${index}"]`).remove()
        controller.index_buttons()
        controller.compileJson()
    }, function (dismiss) {

    })
  }

  index_buttons(){
    document.querySelectorAll(".button").forEach(function(button, index){
      let val = index + 1
      button.querySelector(".index-title").innerText = val
      button.setAttribute("data-index", val)
      button.querySelectorAll("button")[1].setAttribute("data-index", val)
    })
  }

}
