import { Controller } from "stimulus"
import Rails from "@rails/ujs";

export default class extends Controller {
  static targets = [
    "page",
    "back",
    "sourceServer",
    "sourceUser",
    "sourcePass",
    "sourceSiteId",
    "targetServer",
    "targetUser",
    "targetPass",
    "targetSiteId"
  ]

  initialize() {
    // SET INITIAL PAGE AND COUNT
  	this.showPage(0);
  	this.pageCount = (this.pageTargets.length - 1);
  }

  back() {
  	this.index = (this.index - 1);
    // CHECK IF BACK OR CANCEL
  	if(this.index == -1){
      // CLOSE MODAL
  		jQuery(this.element).modal("toggle");
  	} else {
      // GO TO PREVIOUS PAGE
	  	this.showPage(this.index);
  	};
  }

  showPage(index){
  	this.index = index

    // HIDE ALL PAGES AND SHOW THE ACTIVE ONE
  	jQuery(this.pageTargets).hide();
  	jQuery(this.pageTargets[index]).show();

    // UNLOCK NEXT BUTTON
    jQuery(this.nextTarget).prop("disabled", false);
  }

  grabSites(event){
    const [data, status, xhr] = event.detail;
    console.log(data);

    // CHECK IF API CALL WAS SUCCESFUL, OR RESPOND WITH ERROR
    if(data.length > 0){
      // REMOVE EXISTING DROPDOWN SITES TO AVOID DUPLICATES
      jQuery("select[name='omada[site_id]'] option").remove();
      data.forEach(function (site, index) {
        console.log(site);
        // ADD SITES FROM API VALIDATION RESPONSE
        jQuery("select[name='omada[site_id]']").prepend('<option value="' + site["id"] + '">' + site["name"] + '</option>');
      });

      // TRANSFER VALUES FROM PREVIOUS STEPS FOR CREATE CALL
      jQuery(this.targetServerTargets).val(jQuery(this.sourceServerTarget).val());
      jQuery(this.targetUserTargets).val(jQuery(this.sourceUserTarget).val());
      jQuery(this.targetPassTargets).val(jQuery(this.sourcePassTarget).val());

      // SWITCH PAGE
      this.showPage(1);
    } else {
      // VALIDATION FAILED, RAISE ERROR
      this.raiseError("While we could reach your controller, we could not authenticate. Please double check your Omada admin details.");
      // DISABLE SPINNER
      jQuery('.btn-spinner').blur();
    }
  }

  grabWlans(event){
    const [data, status, xhr] = event.detail;
    console.log(data);

    // CHECK IF API CALL WAS SUCCESFUL, OR RESPOND WITH ERROR
    if(data.length > 0){
      // REMOVE EXISTING DROPDOWN SITES TO AVOID DUPLICATES
      jQuery("select[name='omada[wlan_id]'] option").remove();
      data.forEach(function (wlan, index) {
        console.log(wlan);
        // ADD SITES FROM API VALIDATION RESPONSE
        jQuery("select[name='omada[wlan_id]']").prepend('<option value="' + wlan["id"] + '">' + wlan["name"] + '</option>');
      });

      // TRANSFER VALUES FROM PREVIOUS STEPS FOR CREATE CALL
      jQuery(this.targetServerTargets).val(jQuery(this.sourceServerTarget).val());
      jQuery(this.targetUserTargets).val(jQuery(this.sourceUserTarget).val());
      jQuery(this.targetPassTargets).val(jQuery(this.sourcePassTarget).val());
      jQuery(this.targetSiteIdTargets).val(jQuery(this.sourceSiteIdTarget).val());

      // SWITCH PAGE
      this.showPage(2);
    } else {
      // VALIDATION FAILED, RAISE ERROR
      this.raiseError("While we could reach your controller, we could not authenticate. Please double check your Omada admin details.");
      // DISABLE SPINNER
      jQuery('.btn-spinner').blur();
    }
  }

  grabSsids(event){
    const [data, status, xhr] = event.detail;
    console.log(data);

    // CHECK IF API CALL WAS SUCCESFUL, OR RESPOND WITH ERROR
    if(data.length > 0){
      // REMOVE EXISTING DROPDOWN SITES TO AVOID DUPLICATES
      jQuery("select[name='omada[ssid]'] option").remove();
      data.forEach(function (ssid, index) {
        console.log(ssid);
        // ADD SITES FROM API VALIDATION RESPONSE
        jQuery("select[name='omada[ssid]']").prepend('<option value="' + ssid["id"] + '">' + ssid["name"] + '</option>');
      });

      // TRANSFER VALUES FROM PREVIOUS STEPS FOR CREATE CALL
      jQuery(this.targetServerTargets).val(jQuery(this.sourceServerTarget).val());
      jQuery(this.targetUserTargets).val(jQuery(this.sourceUserTarget).val());
      jQuery(this.targetPassTargets).val(jQuery(this.sourcePassTarget).val());
      jQuery(this.targetSiteIdTargets).val(jQuery(this.sourceSiteIdTarget).val());
      jQuery(this.targetWlanIdTargets).val(jQuery(this.sourceWlanIdTarget).val());

      // SWITCH PAGE
      this.showPage(3);
    } else {
      // VALIDATION FAILED, RAISE ERROR
      this.raiseError("While we could reach your controller, we could not authenticate. Please double check your Omada admin details.");
      // DISABLE SPINNER
      jQuery('.btn-spinner').blur();
    }
  }

  createIntegration(event){
    const [data, status, xhr] = event.detail;


    // This was causing problems, status doesn't appear to be available in production. How else can we check if it persisted?

    // CHECK IF CREATION WAS SUCCESFUL, OR RESPOND WITH ERROR
    // if(status == "OK"){
      this.completed();
    // } else {
      // CREATION FAILED, RAISE ERROR
      // this.raiseError("We could not activate this UniFi integration. Please try again.");
    // }
  }

  ajaxError(){
    this.raiseError("We could not connect to your Omada controller, please test your URL and make sure it is reachable from outside of your network.");
  }

  raiseError(message = "We could not connect to your Omada controller, please test your URL and make sure it is reachable from outside of your network."){
    console.log(message);
    // DISPLAY ERROR MESSAGE
    toastr.error(message);
    // DISABLE SPINNER
    jQuery('.btn-spinner').blur();
  }

  completed(){
    // CLOSE MODAL
    jQuery(this.element).modal("hide");

    // DISPLAY SWEET ALERT NOTIFICATION
    swal({
      title: 'Location activated',
      text: "Your WiFi login is now active on your Omada network",
      type: 'success',
      confirmButtonText: 'Continue',
      confirmButtonClass: 'btn btn-primary waves-effect waves-light',
      buttonsStyling: false
    }).then(function () {
      Turbolinks.visit(window.location.href.substr(0, window.location.href.indexOf('#')));
    });
  }
}
