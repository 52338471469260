import { Controller } from "stimulus"
import Rails from "@rails/ujs";

export default class extends Controller {

  static targets = [
    "form"
  ]

  initialize() {

  }

  submit_form(event){
    this.formTarget.submit()
  }

  undo_import(event) {
    event.stopPropagation()
    event.preventDefault()
    let import_id = event.currentTarget.getAttribute("data-import-id")
    swal({
      title: 'Are you sure?',
      text: "Undoing cannot be undone",
      type: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, undo this Import!',
      cancelButtonText: 'Cancel',
      confirmButtonClass: 'btn btn-lg btn-danger waves-effect waves-light m-t-20',
      cancelButtonClass: 'btn btn-lg btn-transparent waves-effect waves-light m-l-10 m-t-20',
      buttonsStyling: false
    }).then(function () {
      document.querySelectorAll(".undo-import-action").forEach(function(elem){
        if ( elem.getAttribute("data-import-id") == import_id) {
          elem.click()
        }
      })
    }, function (dismiss) {
      if (dismiss === 'cancel') {
        // window.location.href = link;
      }
    })
  }


}
