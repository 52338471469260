import { Controller } from "stimulus"
import Rails from "@rails/ujs";

export default class extends Controller {
  static targets = []

  connect(){
    //this.setGrid();

    // UPDATE MATCH HEIGHT IF REVIEWS PRESENT
    if($('#review-cont').length > 1){
      $.fn.matchHeight._update();
    }
  }

  archiveReview(event){
    event.stopPropagation()
    event.preventDefault()
    const id = event.currentTarget.getAttribute('data-id')
    swal({
        title: 'Are you sure?',
        text: "Archiving cannot be undone",
        type: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes, archive it!',
        allowOutsideClick: true,
        cancelButtonText: 'Cancel',
        confirmButtonClass: 'btn btn-lg btn-danger waves-effect waves-light m-t-20',
        cancelButtonClass: 'btn btn-lg btn-transparent waves-effect waves-light m-l-10 m-t-20',
        buttonsStyling: false
    }).then(function () {
        $.ajax({
          url: ('review/archive/' + id),
          type: 'DELETE',
          success: function(result) {
            $("#review-" + id).remove();
            $('#review-cont').isotope({
              filter: '*',
              animationOptions: {
                  duration: 750,
                  easing: 'linear',
                  queue: false
              }
            });
          }
        });
    }, function (dismiss) {
        if (dismiss === 'cancel') {
          // window.location.href = link;
        }
    })
  }

  setGrid(){
    // SET DYNAMIC GRID
    $('#review-cont').isotope({
      filter: '*',
      animationOptions: {
          duration: 750,
          easing: 'linear',
          queue: false
      }
    });
  }
}
